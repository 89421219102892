import React, {useState, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import './login.css';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { playGame, loginUser, selectTempGameId } from '../store/screenSlice';


library.add(faPhone);


const Login = () => {
    const [loginDisabled, setLoginDisabled] = useState(false);
    const dispatch = useDispatch();
    const [linkHref, setLinkHref] = useState('');
    const [loading, setLoading] = useState(false);
    const id = useSelector(selectTempGameId); 
    const [msisdnValue, setMsisdnValue] = useState('');
    const [e_msisdnValue, setE_MsisdnValue] = useState('');
   
    const resetMsisdn = () => {
        setMsisdnValue('');
        setE_MsisdnValue('');
    }

    const handleChangeMsisdn = (event) => {
        setMsisdnValue(event.target.value);
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const e_msisdn = params.get('E_MSISDN');
        const msisdn = params.get('MSISDN');
    
        if (e_msisdn) {
          const e_msisdnElement = document.getElementById('e_msisdn') as HTMLInputElement;
          e_msisdnElement.value = e_msisdn;
          handleLogin();
        }
        if (msisdn) {
            const msisdnElement = document.getElementById('msisdn') as HTMLInputElement;
            //msisdnElement.value = atob(getMsisdn);
            msisdnElement.value = msisdn;
            handleLogin();
          }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = () => {
        setLinkHref('');
        setLoginDisabled(true);
        //setLoading(true);
        const error_message = document.getElementById('error_message') as HTMLElement;
        const msisdnElement = document.getElementById('msisdn') as HTMLInputElement;
        const e_msisdnElement = document.getElementById('e_msisdn') as HTMLInputElement;
        const msisdn = msisdnElement ? msisdnElement.value : '';
        const e_msisdn = e_msisdnElement ? e_msisdnElement.value : '';
        const validNumbers = /^[0-9]+$/;
        
        if(!e_msisdn) {
            if(!msisdn) {
                msisdnElement.focus();
                error_message.textContent='Ingrese su n\u00FAmero de tel\u00e9fono.';
                setLoginDisabled(false);
                setLoading(false);
                return false;
            }
            
            if(msisdn.length<6 || !msisdn.match(validNumbers) ) {
                msisdnElement.focus();
                error_message.textContent='El tel\u00e9fono no parece v\u00E1lido.';
                setLoginDisabled(false);
                setLoading(false);
                return false;
            }
        }


        const data = {
            e_msisdn: e_msisdn,
            msisdn: msisdn,
            client: '',
            country: ''
        }

        error_message.textContent='\u00A0';

        axios.post(`${process.env.REACT_APP_API_URL}?call=login`, data)
        .then(response => {
            if(response.data.result===1) { //success
                dispatch(loginUser());
                dispatch(playGame(id));
            } else { //no valido
                if (error_message) {
                    error_message.textContent = 'Este n\u00FAmero de tel\u00e9fono no est\u00E1 registrado';
                }
                if(response.data.redirect) {
                    setLinkHref(response.data.redirect);
                }
                setLoginDisabled(false);
                setLoading(false);
            }
        })
        .catch(error => {
            console.error(error);
            setLoginDisabled(false);
            setLoading(false);
        });
    }

    return (
        <>
            <div id="containerLogin">
                <div className="d-flex justify-content-center h-100" id="innerContainer">
                    <div className="card">
                        <div className="card-header" id="header">
                            <h3>GameFlicks</h3>
                        </div>
                        <div className="card-body" id="formDiv">
                            <form id="form">
                                <div className="input-group form-group" id="fields">
                                    <div className="input-group-prepend">
                                    <span className="input-group-text" id="phoneIcon"><FontAwesomeIcon icon="phone"/></span>
                                    </div>
                                    <input type="text" className="form-control" id="msisdn" value={msisdnValue} placeholder="Telefono" onClick={resetMsisdn} onChange={handleChangeMsisdn} />
                                    <input type="hidden" id="e_msisdn" value={e_msisdnValue} />
                                </div>
                                <div className="form-group" id="logindiv">
                                    <input type="button" value="Login" id="login" className="btn login_btn" onClick={handleLogin} disabled={loginDisabled} />
                                </div>
                            </form>
                        </div>
                        <div id="container_error">
                            <div id="error_message">&nbsp;</div>
                            <div id="success_message"><img src={process.env.PUBLIC_URL + '/images/loading.gif'} className="loading" alt="loading" style={{ display: loading ? 'block' : 'none' }} /></div>
                        </div>
                        <div className="card-footer" id="divLink" style={{ display: linkHref ? 'block' : 'none' }}>
                            <div className="d-flex justify-content-center links">
                                No tienes una cuenta aun?<a href={linkHref} id="landing">Suscr&iacute;bete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
